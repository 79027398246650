import React, { FC, useEffect, useState } from "react";
import { Expert as IExpert } from "types/user";
import { ExpertPage } from "screens/server/ExpertPage";
import AuthModals from "components/AuthModals/AuthModals";
import { Footer } from "components/Footer";
import axios from "axios";
import { BrowserRouter } from "react-router-dom";
import dynamic from "next/dynamic";
import { ErrorBoundary } from "@sentry/nextjs";
import ErrorView from "components/ErrorView/ErrorView";

const AppEntryPoint = dynamic(() => import("screens/browser/App"), {
  ssr: false,
});

const Expert: FC<{
  expert: IExpert;
  acceptLanguageHeader: string;
  publicId?: string;
  tips?: any;
  communities?: any;
  events?: any;
  tutorials?: any;
  offers?: any;
  isAuthenticated: boolean;
}> = ({
  expert,
  acceptLanguageHeader,
  publicId,
  tips,
  communities,
  events,
  tutorials,
  offers,
  isAuthenticated,
}) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient && isAuthenticated) {
    return null;
  }

  if (isAuthenticated && isClient) {
    return (
      <ErrorBoundary fallback={() => <ErrorView />}>
        <BrowserRouter>
          <AuthModals />
          <AppEntryPoint />
        </BrowserRouter>
      </ErrorBoundary>
    );
  }
  return (
    <>
      <AuthModals />
      <ExpertPage
        expert={expert}
        acceptLanguageHeader={acceptLanguageHeader}
        publicId={publicId}
        tipsNext={tips}
        communitiesNext={communities}
        eventsNext={events}
        tutorialsNext={tutorials}
        offersNext={offers}
      />
      <Footer />
    </>
  );
};

export const getServerSideProps = async ({
  req,
  resolvedUrl,
  query,
  params,
}) => {
  const cookies = req.cookies;
  const acceptLanguageHeader = req.headers["accept-language"];
  const { publicId } = params;

  try {
    // Dirty hack for cbl > fca redirect. Will remove later.
    if (resolvedUrl.includes("/cbl")) {
      return {
        redirect: {
          destination: "/fca",
          permanent: false,
        },
      };
    }

    if (resolvedUrl.includes("%23")) {
      const decodedUrl = decodeURIComponent(resolvedUrl);
      const correctedUrl = decodedUrl.replace("%23", "#");

      return {
        redirect: {
          destination: correctedUrl,
          permanent: false,
        },
      };
    }
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_REST_URL}/experts/v1/experts/${publicId}`,
      {
        headers: {
          cookie: `access_token=${cookies?.access_token}; refresh_token=${cookies?.refresh_token}`,
        },
        maxRedirects: 0,
        validateStatus: (status) =>
          status < 400 || status === 301 || status === 307,
      }
    );

    if (response.status === 301 || response.status === 307) {
      const location = response.headers.location;
      if (location) {
        return {
          redirect: {
            destination: location,
            permanent: response.status === 301,
          },
        };
      }
    }

    const expert = response.data;

    const user = await fetch(
      `${process.env.NEXT_PUBLIC_REST_URL}/users/v1/users/me`,
      {
        headers: {
          cookie: `access_token=${cookies?.access_token}; refresh_token=${cookies?.refresh_token}`,
        },
      }
    )
      .then((res) => res.json())
      .catch(() => null);

    const isAuthenticated = Boolean(user?.id);

    if (!expert?.id) {
      return {
        props: {
          expert: {},
          acceptLanguageHeader,
          publicId,
          tips: [],
          communities: [],
          events: [],
          tutorials: [],
          offers: [],
          isAuthenticated,
        },
      };
    }

    const [tips, communities, events, tutorials, offers] = await Promise.all([
      fetch(
        `${process.env.NEXT_PUBLIC_REST_URL}/offers/v1/tip-offer/for-expert?expertid=${expert?.id}`
      ).then((res) => res.json()),
      fetch(
        `${process.env.NEXT_PUBLIC_REST_URL}/offers/v1/community-offer/for-expert?expertid=${expert?.id}`
      ).then((res) => res.json()),
      fetch(
        `${process.env.NEXT_PUBLIC_REST_URL}/offers/v1/event-offer/for-expert?expertid=${expert?.id}`
      ).then((res) => res.json()),
      fetch(
        `${process.env.NEXT_PUBLIC_REST_URL}/offers/v1/tutorial-offer/for-expert?expertid=${expert?.id}`
      ).then((res) => res.json()),
      fetch(
        `${process.env.NEXT_PUBLIC_REST_URL}/offers/v1/session-offer/for-expert?expertid=${expert?.id}`
      ).then((res) => res.json()),
    ]);

    const newPublicId = expert.publicId;

    if (newPublicId && publicId !== newPublicId) {
      return {
        redirect: {
          destination: `/${newPublicId}`,
          permanent: false,
        },
      };
    }

    return {
      props: {
        expert,
        acceptLanguageHeader,
        publicId,
        tips,
        communities,
        events,
        tutorials,
        offers,
        isAuthenticated,
      },
    };
  } catch (error) {
    return {
      props: {
        expert: {},
        acceptLanguageHeader,
        publicId,
        tips: [],
        communities: [],
        events: [],
        tutorials: [],
        offers: [],
        isAuthenticated: false,
        error: error.message,
      },
    };
  }
};

export default Expert;
